import '../1_styles/HomePage.css'
import homeSplashBlack1 from '../2_assets/images/homeSplashBlack1.svg'
import homeSplashAqua1 from '../2_assets/images/homeSplashAqua1.svg'
import homeAvatar from '../2_assets/images/homeAvatar.png'
import homeSplashBlack2 from '../2_assets/images/homeSplashBlack2.svg'
import homeSplashWhite1 from '../2_assets/images/homeSplashWhite1.svg'
import bluesky from '../2_assets/images/homeIconBluesky.svg'
import instagram from '../2_assets/images/homeIconInstagram.svg'
import github from '../2_assets/images/homeIconGitHub.svg'
import linkedin from '../2_assets/images/homeIconLinkedin.svg'
import email from '../2_assets/images/homeIconEmail.svg'
import config from '../4_functions/0_config.json'

export function HomePage() {
  
  return (
    
    <div id='idallHomeFrame' className='allHomeFrame'>

      <div className='frameone'>
        <img src={homeSplashBlack1} className='homeSplashBlack1'/>
        <img src={homeSplashAqua1} className='homeSplashAqua1'/>
        <img src={homeAvatar} className='homeAvatar'/>

        <div className='seeYouSoonFrame'><p className='seeYouSoon'>see you soon</p></div>

        <div className="wrapper">
          <div className="topOneBin">
            <p>01010100 01101000 01100101 01110010 01100101 00100000 01101001 01110011 00100000 01101110 01101111 00100000 01110011 01110101 01100010 01101100 01101001 01101101 01101001 01101110 01100001 01101100 00100000 01101101 01100101 01110011 01110011 01100001 01100111 01100101 00100000 01101000 01100101 01110010 01100101 00101110</p>
            <p>01010100 01101000 01100101 01110010 01100101 00100000 01101001 01110011 00100000 01101110 01101111 00100000 01110011 01110101 01100010 01101100 01101001 01101101 01101001 01101110 01100001 01101100 00100000 01101101 01100101 01110011 01110011 01100001 01100111 01100101 00100000 01101000 01100101 01110010 01100101 00101110</p>
          </div>
        </div>

        <div className="wrapperTwo">
          <div className="topTwoBin">
            <p>01010100 01101000 01100101 01110010 01100101 00100000 01101001 01110011 00100000 01101110 01101111 00100000 01110011 01110101 01100010 01101100 01101001 01101101 01101001 01101110 01100001 01101100 00100000 01101101 01100101 01110011 01110011 01100001 01100111 01100101 00100000 01101000 01100101 01110010 01100101 00101110</p>
            <p>01010100 01101000 01100101 01110010 01100101 00100000 01101001 01110011 00100000 01101110 01101111 00100000 01110011 01110101 01100010 01101100 01101001 01101101 01101001 01101110 01100001 01101100 00100000 01101101 01100101 01110011 01110011 01100001 01100111 01100101 00100000 01101000 01100101 01110010 01100101 00101110</p>
          </div>
        </div>
        
      </div>

      <div className='frametwo'>

        <div className='rightTopFrame'>
          <img src={homeSplashWhite1} className='homeSplashWhite1'/>
          <img src={homeSplashBlack2} className='homeSplashBlack2'/>
          <div className='textBottom'>
            <p className='textHi'>hi, I'm</p>
            <div className='nameContainer'>
              <p className='nameAqua'>julio kozarewicz</p>
              <p className='nameRed'>julio kozarewicz</p>
              <p className='namewhite'>julio kozarewicz</p>
            </div>
            <p className='textEngineer'>software engineer</p>
          </div>

          <div className='vacancyAll'>
            <div className='anchorLeft'>
              <div></div>
              <div></div>
            </div>
            <div className='signBkg'>
              <div>n</div>
              <div>o</div>
              <p>v</p>
              <p>a</p>
              <p>c</p>
              <p>a</p>
              <p>n</p>
              <p>c</p>
              <p>y</p>
            </div>
          </div>

        </div>

        <div className='rightBottomFrame'>
          <p>social media!</p>
          <p>find me around</p>

          <div className='sociaMedia'>

            <button  className='socialBTN' onClick={
              () => window.open(config.blueskyProfile, '_blank')
            }>
              <img src={bluesky} alt="bluesky" />
            </button>
            <button  className='socialBTN' onClick={
              () => window.open(config.instagramProfile, '_blank')
            }>
              <img src={instagram} alt="instagram" />
            </button>
            <button  className='socialBTN' onClick={
              () => window.open(config.githubProfile, '_blank')
            }>
              <img src={github} alt="github" />
            </button>
            <button  className='socialBTN' onClick={
              () => window.open(config.linkedinProfile, '_blank')
            }>
              <img src={linkedin} alt="linkedin" />
            </button>
            <button className='socialBTN' onClick={
              () => window.location.href = `mailto:${config.emailSendMessages}`
            }>
              <img src={email} alt="email" />
            </button>

          </div>

        </div>

      </div>

    </div>

  )
}