import '../1_styles/AboutMePage.css'

export function AboutMePage() {
  return (

    <>

      <section id='IDsectionAboutMe'>
        <div className='curtainAbout'></div>
        <div id='IdallContentAboutMe'>
          <h1 className='titleAboutMe'>about___me</h1>
          <p>As a software engineer, backend development is my area of expertise. I also have some experience in interface design, mobile apps, and front-end development. I started my programming career by creating machine learning models for data forecasting while pursuing my first degree in economics. Later, I expanded my understanding of web development in Software Engineering. My work is often influenced by my passions for science fiction, mathematics, art, technology, photography, and video games. I’m excited to use my skills to develop creative and practical solutions that meet your needs!</p>
        </div>
      </section>

    </>

  )
}